.mdd, .mdd-pad {
  display: none;
}
.ie-only {
  display: block !important;
}
.clearfix:after, .grid:after, .flex-list:after {
   content: " ";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}
.masthead-logo {
  background-image: url("/common/images/logo_ie.png");
}
.loose > .container > .link-grid-list.no-caption {
  height: auto;
}
.loose .link-grid-link .link-grid-img-wrap {
  height: 3em;
  line-height: 3em;
}
.icon-pdf {
  background-image: url("/common/images/icon/icon-pdf-ie.png");
}
.irtop-bg-link[data-bg="1"], 
.irtop-bg-link[data-bg="3"], 
.irtop-bg-link[data-bg="5"], 
.irtop-bg-link[data-bg="6"], 
.irtop-bg-link[data-bg="7"], 
.irtop-bg-link[data-bg="8"],
.investor-bg-link[data-bg="1"], 
.investor-bg-link[data-bg="2"], 
.investor-bg-link[data-bg="3"], 
.investor-bg-link[data-bg="4"] {
  background-size: 100% 100%;
  display: block;
  height: 165px !important;
  position: relative !important;
}
.investor-bg-link[data-bg="1"], 
.investor-bg-link[data-bg="2"], 
.investor-bg-link[data-bg="3"], 
.investor-bg-link[data-bg="4"] {
  height: 180px !important;
}
.irtop-bg-link[data-bg="5"], 
.irtop-bg-link[data-bg="6"], 
.irtop-bg-link[data-bg="7"], 
.irtop-bg-link[data-bg="8"] {
  height: 155px !important;
  padding-bottom: 0 !important;
}
.irtop-bg-link[data-bg="4"] {
  background-size: 100% 100%;
  display: block;
  height: 340px;
  position: static !important;
}
.grid3.investor-icon-link {
  width: 30% !important;
}